//公用的样式文件
@import "./mixin";
@import "./variable";
body {
    font-family: "Microsoft Yahei", Helvetica, Arial, sans-serif;
    color: #3b3d3e;
}

a {
    color: #666;
}

a:hover, a:focus {
    color: #666;
    text-decoration: none;
}

.block {
    display: block;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}

.margin-center {
    margin-left: auto;
    margin-right: auto;
    display: table;
    width: auto;
}

.text-justify {
    text-align: justify;
}

.text-underline {
    text-decoration: underline;
}

.text-top {
    vertical-align: top;
}

.text-middle {
    vertical-align: middle;
}

.text-bottom {
    vertical-align: bottom;
}

.text-nobr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-nowrap {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.pos-relative {
    position: relative;
}

.pos-absolute {
    position: absolute;
}

.pos-static {
    position: static;
}

.text-dark {
    color: #000;
}

.text-light-dark {
    color: #666;
}

.text-jet-dark {
    color: #333;
}

.text-white, a.text-white {
    color: #fff;
}

.text-red, a.text-red {
    color: #ff4136;
}

.text-light-red, a.text-light-red {
    color: #f74d4d;
}

.text-blue, a.text-blue {
    color: #006666;
}

.text-highlight-blue, a.text-highlight-blue {
    color: #5badab;
}

.text-high-blue, a.text-high-blue {
    color: #4c6aae;
}

a.text-light-blue, .text-light-blue {
    color: #89afc3;
}

.text-orange, a.text-orange {
    color: #fa8100;
}

.text-dark-orange {
    color: #ff7200;
}

.text-gray, a.text-gray {
    color: #454545;
}

.text-light-gray {
    color: #a3a3a3;
}

.txt-idt1 {
    text-indent: 1rem;
}

.txt-idt2 {
    text-indent: 2rem;
}

.n {
    font-weight: normal;
    font-style: normal;
}

.b {
    font-weight: bold;
}

.i {
    font-style: italic;
}

.wf {
    width: 100%;
}

.hf {
    height: 100%;
}

.nowrap {
    white-space: nowrap;
}

.bk {
    word-wrap: break-word;
}

.rel {
    position: relative;
}

.abs {
    position: absolute;
}

.bg-white {
    background-color: #ffffff;
}

.bg-gray {
    background-color: #aaaaaa;
}

.bg-light-gray {
    background-color: #f0efed;
}

.bg-silver {
    background-color: #dddddd;
}

.bg-black {
    background-color: #111111;
}

.bg-white {
    background-color: #fff;
}

.bg-none {
    background-image: none !important;
}

.bgfb {
    background-color: #fbfbfb;
}

.bgf5 {
    background-color: #f5f5f5;
}

.bgf0 {
    background-color: #f0f0f0;
}

.bgeb {
    background-color: #ebebeb;
}

.bge0 {
    background-color: #e0e0e0;
}

.bd1 {
    border: 1px solid #ddd;
}

.bd2 {
    border: 2px solid #ddd;
}

.bd3 {
    border: 3px solid #ddd;
}

.bd0 {
    border-width: 0;
}

.bdl0 {
    border-left: 0 !important;
}

.bdt1 {
    border-top: 1px solid #ccc;
}

.bdr1 {
    border-right: 1px solid #ccc;
}

.bdl1 {
    border-left: 1px solid #ccc;
}

.bdb1 {
    border-bottom: 1px solid #eee;
}

.mr20 {
    margin-right: 20px;
}

.mr5 {
    margin-right: 5px;
}

.wraper-cd-center {
    display: flex;
    justify-content: center;
}
.wraper-cd-right {
    display: flex;
    justify-content: flex-end;
}
//ant modal 样式
.ant-modal-content {
    color: #6B7C93;
}
//Modal底部两个按钮
.ant-modal-footer{
    border-top: 0;
    padding:10px 16px 20px;
    div{
        width:400px;
        margin-left:auto;
        margin-right:auto;
    }
    div::after{
        clear: both;
        content: '.';
        display: block;
        height: 0;
        visibility: hidden;
    }
    button {
        width: 150px;
        margin: 0 3px;
        height: 40px;
        box-shadow: $box-shadow-color;
        border-radius: $box-radius-size;
    }
    button:first-child{
        float:left;
        @include btn_no();
    }
    button:last-child{
        float:right;
        @include btn_opr();
    }
}  //結束


.ant-modal-header{
    padding: 0 24px;
    -webkit-box-shadow: 0 7px 7px -7px #d4d7d9;
    -moz-box-shadow:  0 7px 7px -7px #d4d7d9;
    box-shadow:  0 7px 7px -7px #d4d7d9;
    .ant-modal-title {
        line-height: 40px;
        font-weight: bold;
        color: #6B7C93;
        text-align: center;
        font-size:20px;
    }
}
.ant-modal-body{
    padding:24px 30px;
}
.ant-modal-close-x {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
}
//--end
.com-clearfix::after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
}
.com-fl {
    float: left;
}
.com-fr {
    float: right;
}
.com-pr{
    position:relative;
}
.com-pa{
    position: absolute;
}
.com-hand{
    cursor: pointer;
}
.com-hand-default{
    cursor:default;
}
.com-text-center{
    text-align: center;
}
.com-text-left{
    text-align:left;
}
.com-text-center{
    text-align:center;
}
.com-inline-block {
    display: inline-block;
}
//flex居中布局
.flex-center{
    display:flex !important;
    justify-content:center;
    align-items:center;
}
.flex-between-start{
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
}
.flex-align-start{
    display:flex !important;
    justify-content:flex-start;
    align-items:flex-start;
}
.flex-between{
    display:flex !important;
    justify-content:space-between;
    align-items:center;
}
.flex-start-middle{
    display:flex !important;
    justify-content:flex-start;
    align-items:center;
}
.flex-end-middle{
    display:flex !important;
    justify-content:flex-end;
    align-items:center;
}

.flex-around-center{
    display:flex !important;
    justify-content:space-around;
    align-items:center;
}
//垂直方向居中布局
.flex-direction-between{
    display:flex !important;
    flex-direction:column;
    justify-content:space-between;
}
.flex-direction-center{
    display:flex !important;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
//flex实现均分布局且自动换行
.flex-average-wrap{
    padding:3%;
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    align-items:center;
    flex-direction: row;
}

//查询-禁用等按钮
.com-btn-opr{
    @include btn-opr();
}
//重置-取消-返回
.com-btn-no{
    @include btn-no();
}

//Button组件默认样式
.ant-btn-default{
    @include btn-no();
}
.ant-btn-primary{
    @include btn-opr();
}
.com-middle-btn-size{
    width:$btn-middle-width;
    height:$btn-middle-height;
    box-shadow: $box-shadow-color;
    border-radius: $box-radius-size;
}
.com-shadow-radius{
    box-shadow: $box-shadow-color;
    border-radius: $box-radius-size;
}

//ant table 样式
.ant-table-thead {
    overflow: visible;
    box-shadow: $box-shadow-color;
    border-radius: $box-radius-size;
}
.ant-table-thead > tr > th {
    text-align: center!important;
}
.ant-table > .ant-table-content {
    background: #fff;
    box-shadow: $box-shadow-color;
    border-radius: $box-radius-size;
}

.ant-table-tbody {
    tr {
        td {
            font-size: 14px;
            font-weight: 400;
        }
    }
    text-align: center;
    color: #6B7C93;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    box-shadow: $box-shadow-color;
    border-radius: $box-radius-size;

}
span.ant-table-column-title {
    font-weight: bold;
    color: #6B7C93;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
    background: none;
}
.ant-table-thead > tr > th.ant-table-column-sort{
    background: none;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    padding:10px;
}
//ant table 样式 end
//a{
//    color:#1890ff;
//}
.com-hand-color{
    color: #2E83FF!important;
}

//分页器
.ant-table-pagination.ant-pagination{
    float:none;
    text-align:center;
}
.com-h5-size{
    font-size:20px;
}
.com-h5-height{
    line-height:50px;
    height:50px;
}
//公用不同类型Button
.com-table-btn{
    margin-left:5px;
    margin-right:5px;
    height:30px;
}
//公用两个Button,左右不一样样式
.com-diff-two-btn{
    text-align: center;
    height:50px;
    line-height:50px;
    margin-bottom:30px;
    button:first-child {
        margin-right: 100px;
    }
}
.com-only-bun{
    text-align: center;
    height:50px;
    line-height:50px;
    button:{
        width:150px;
    }
}
//类似a标签样式
.com-hand-blue{
    cursor: pointer;
    color: #2E83FF!important;
    margin-left:5px;
    margin-right:5px;
}
.com-small-btn-size {
    width:$btn-samll-width;
    height:$btn-small-height;
    box-shadow: $box-shadow-color;
    border-radius: $box-radius-size;
}
//c3实现上下左右居中
.com-vertical-level-center{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
//表头左对齐
.ant-table-thead > tr > th.ant-table-column-has-actions.left{
    text-align: left !important;
    
  }

