.cie-box{
    width: 100%;
    .cie-w{
        width: 100%;
        border-bottom: 1px solid #ccc;
        .cie-title{
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
            span{
                font-size: 22px;
                font-weight: 500;
                margin-left: 20px;
            }
        }
    }
    .cie-step{
        width: 1000px;
        margin: 60px auto;
    }
    .info-box{
        margin: 30px 0 20px;
        padding: 0 40px;
        ul{
            li{
                padding-left:20px ;
                line-height: 30px;
            }
        }
    }
    .ci-title{
        width: 100%;
        height: 38px;
        line-height: 38px;
        background: #efebeb;
    }
}
.face-box{
    padding: 30px 0;
    text-align: center;
    width: 660px;
    height: 600px;
    margin: 0 auto;
}
.MCf-box{
    width: 660px;
    padding: 88px 38px;
    margin: 0 auto;
    p{
        width: 80%;
        color: blue;
    }
    h2{
        margin: 20px 0;
    }
    .MCF-account{
        margin: 20px 0;
        span{
            width: 80px;
            display: inline-block;
            text-align: right;
            font-size: 16px;
        }
        span:nth-child(1){
            margin-right: 18px;
        }
    }
}
.result-box{
    width: 260px;
    margin: 0 auto;
    text-align: center;
    h1{
        margin: 20px 0;
        color: orange;
    }
}