@import "../../assets/css/mixin";
.client-info-manage-wrapper {
  .ant-form .btn-item {
        text-align: center;
        margin-top: 30px;

        button:first-child {
          margin-right: 100px;
        }
  }
  .title-com{
    // margin:30px auto;
    // width:1050px;
    width: 100%;
    display: flex;
    align-items: center;
    // width: 70%;
    h6{
      font-size:16px;
      width: 11%;
    }
    .title-divider{
      width: 75%;
    }
  }
  .inquire-form-wrapper {

  }

  .add-client-info {
    .ant-steps {
      height: 110px;
      z-index: 3;
      background: #2E83FF;
      padding: 20px 20px;
      .ant-steps-item-title::after {
        height: 2px;
        background: #97C1FF;
      }
    }
    .ant-steps-item-icon {
      background: #fff !important;
      margin-left: 12px;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
      margin-right: 0;
      width: 80px;
    }

    .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title::after {
      background: #fff;
    }

    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
      color: #1890ff;
      border-color: #fff !important;
    }

    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      color: #2E83FF;
    }

    .ant-steps-item-wait .ant-steps-item-icon {
      background: #97C1FF !important;
    }

    .ant-steps-item-title {
      color: #fff !important;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
      max-width: 140px;
      position: absolute;
      white-space: normal;
      left: 0;
      top: 40px;
      color: #fff;
      letter-spacing: 1px;
      font-size:$second-level-size;
    }
    .upload-img-request{
      ul li{
        height:30px;
        line-height:30px;
        font-size:14px;
      }
    }
  }
  .choose-business-type-wrapper{
    margin:30px 10px 0;
    .ant-form label{
      font-size:$second-level-size;
    }
    .ant-radio-group  .ant-radio-wrapper,
    .ant-checkbox-group .ant-checkbox-wrapper{
      margin-right: 10px;
      font-size: 16px;
      width: 140px;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper{
      margin-left:0;
    }
    .btn-item {
        text-align: center;
        margin-top: 30px;
        button:first-child {
          margin-right: 100px;
        }
      }
    .ant-form-item-control{
      padding: 30px 300px;
    }
    .view-example{
       margin-left:80px;
    }
  }
  .add-business-info-wrapper{
    width: 100%;
    padding-left: 16%;
    .divider {
      // width:920px;
      border-color: #1890ff;
      border-style: dashed;
      border-width: 2px 0 0;
      margin: 14px 0;
    }
    .ant-form{
      // width:1050px;
      margin:0 auto;
    }
    .date-interval{
      height:40px;
      line-height:40px;
      color: #b9b9b9;
    }
  }

  .flex-end-middle{
    justify-content: flex-start !important;
  }

  .paddingLeft323px{
    padding-left: 363px;
  }

  .paddingLeft340px{
    padding-left: 380px;
  }

  .paddingLeft78px{
    padding-left: 118px;
  }

  .paddingLeft343px{
    padding-left: 383px;
  }

  .paddingLeft40px{
    padding-left: 40px;
  }
}
