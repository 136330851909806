.messageDetails {
  .message {
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 4px;
    .title {
      background-color: black;
      color: #fff;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
    }
    .content {
      padding: 20px;
    }
  }
}

.handle {
  color: blue;
  cursor: pointer;
}

/* 列表对齐 */
// td.column-left {
//   text-align: left !important;
// }

td.column-right {
  text-align: right !important;
}

/* 窗口宽度 */
// .ant-modal-content {
//   width: 900px;
//   left: -100px;
//   top: -50px;
// }

/* json样式 */
__json-pretty__ {
  line-height: 1.3;
  color: #66d9ef;
  background: #272822;
  overflow: auto;
}

.__json-pretty__ .__json-key__ {
  /*color: #f92672*/
  color: #204a87
}

.__json-pretty__ .__json-value__ {
  color: #a6e22e
}

.__json-pretty__ .__json-string__ {
  /*color: #fd971f*/
  color: #4e9a06
}

.__json-pretty__ .__json-boolean__ {
  color: #ac81fe
}

.__json-pretty-error__ {
  line-height: 1.3;
  color: #66d9ef;
  background: #272822;
  overflow: auto
}
