@import "../../assets/css/mixin.scss";



//票据转让详情
.CtTransforDetail{
    ul{
        width: 100%;
        // overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        font-size: 20px;
        li{
            // float: left;
            // margin: 0 30px;
           width: 25%;
           text-align: center;
        }
    }
    .ul{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li{
           display: flex;
           flex-direction: column;
           align-items: center;
           width: 25%;
        }
    }
    .goBack{
        margin : 20px 0;
    }
    .CtTransforDetail-ticketContent{
        width: 100%;
        display: flex;

        .CtTransforDetail-ticketContent-front{
            flex: 1;
            padding: 20px;
            table{
                tr{
                    td{
                        padding: 3px 15px;
                    }
                }
            }
           
        }
        .CtTransforDetail-ticketContent-behind{
            flex: 1;
            padding: 20px;
           
        }
        
    }
}
// //票据签收详情
// .CtSignDetail{
//     width: 100%;
//     display: flex;
//     .CtSignDetail-record{
//         flex: 1;
//         .goBack{
//             margin: 16px 0;
//         }
//     }
//     .CtSignDetail-ticketContent{
//         flex: 1;

//     }
// }
//票据转让详情
.CtTransforDetailDetail{
    width: 100%;
    display: flex;
    .CtTransforDetailDetail-record{
        flex: 1;
        .ant-col-xs-24{
            // text-align: left !important;
            margin-left: 0px !important;
        }
    }
}


.fontSize44px{
    font-size: 44px;
}
.fontSize24px{
    font-size: 24px;
}

.wrap{
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    div{
        flex: 1;
    }
}
.AlignLeft{
    text-align: left !important;
}
.history-table{
    width: 40%;
}
.table{
    width: 100%
}
.marginTopOrBottom{
   margin-top: 30px;
   margin-bottom: 30px;
}
.textAlignCenter{
    text-align: center;
}
.ticketHeight{
    height: 400px;
}