@import "../../assets/css/mixin";
.home{
    width: 80%;
    margin-left: 10%;
    .wrap{
        width: 100%;
        border: 3px solid black;
        display: flex;
        justify-content: center;
       
            ul{
                flex: 1;
                margin-bottom: 0px;
                padding: 30px 0;
                overflow: hidden;
                li{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    text-align: center;
                    font-size: 20px;
                }
            }
        
    }
    .marginBottom{
        margin-bottom: 80px;
    }
    .fontSize30px{
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        font-size: 28px !important;
        padding: 0 10px;
        cursor:pointer;
    }
    .marginBottom-flex{
        display: flex;
        justify-content: space-between;
    }
}

