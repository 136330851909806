@import "../../assets/css/mixin.scss";
.client-relate-maintain-wrapper{
  .btn-item {
    text-align: center;
    margin-top: 30px;
    margin-bottom:30px;
    button:first-child {
      margin-right: 100px;
    }
  }
  .search-info-wrapper{

  }
  .relate-maintain-wrapper{
    .ant-tabs{
        width:100%;

    }
  }
  .company-list-wrapper{
    padding:0 30px 30px;
    .describe-maintain{
      height:40px;
      margin-top:10px;
      margin-bottom:20px;
    }
    h5{
      font-size:18px;
      margin-bottom:0;
    }
  }
  .add-modal-wrapper{
    .ant-form{
      margin-bottom:20px;
    }
    .ant-form-inline .ant-form-item{
      margin-right:0;
    }
  }

}
