//sass变量
//网站主题风格变量
//背景主题色
$bg-clr-theme1: red;//主题色1
$bg-clr-theme2: yellow;//主题色2
$bg-clr-theme3: pink;//主题色3

//字体颜色
$ft-clr-theme1 : yellow;//字体主题颜色1
$ft-clr-theme2 : #652BF5;//字体主题颜色2
$ft-clr-theme3 : deepskyblue;//字体主题颜色3

//字体大小
$ft-size-theme1:14px;
$ft-size-theme2:16px;
$ft-size-theme3:18px;
              //--有实际操作的按钮
//按钮背景色
$btn-opr-bg-clr-theme1:#1890ff;
$btn-opr-bg-clr-theme2:red;
$btn-opr-bg-clr-theme3:yellow;

//按钮字体颜色
$btn-opr-ft-clr-theme1:#fff;
$btn-opr-ft-clr-theme2:red;
$btn-opr-ft-clr-theme3:blue;

              //--无实际操作的按钮
//按钮背景色
$btn-no-bg-clr-theme1:#fff;
$btn-no-bg-clr-theme2:red;
$btn-no-bg-clr-theme3:yellow;

//按钮字体颜色
$btn-no-ft-clr-theme1:#6B7C93;
$btn-no-ft-clr-theme2:#333;
$btn-no-ft-clr-theme3:blue;

//边框颜色
$btn-no-bdr-clr-theme1:#d9d9d9;
$btn-no-bdr-clr-theme2:#d9d9d5;
$btn-no-bdr-clr-theme3:#d9d9d4;



//box-shadow 阴影
$box-shadow-color : 0 2px 5px #d4d7d9;
//border-radius
$box-radius-size : 4px;

//中型按钮的大小
$btn-middle-width:150px;
$btn-middle-height:40px;

//小型按钮的大小
$btn-samll-width:64px;
$btn-small-height:32px;
//二级标题字体大小
$second-level-size:18px;







