.signingConfiguration_style {
    .h_text_style {
        margin-bottom: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
    }
    .editable-cell {
        position: relative;
    }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
    }

    .editable-row:hover .editable-cell-value-wrap {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 11px;
    }

    .blue-background-class {
       background-color: #eeeeee; 
       border-color: #eeeeee;
    }

    .text-h-style {
        color: #1890ff;
        cursor:pointer;
        text-decoration: underline;
    }
    
    .signing-wrap-mover {
        cursor: move;
    }
}
