.addIssue-contant{
    .ant-row{
        margin: 20px 0;
    }
}
.btn-box{
    button{
        margin: 0 12px;
    }
}
.addIssue-top{
    display: -webkit-flex;
    display: flex;
    .addIssue-companyInfo{
        h1{
            font-size: 26px;
        }
        width: 33.33%;
        text-align: center;
    }
}
.addIssue-left{
    font-size: 16px;
    font-weight: 600;
    height: 250px;
    line-height: 250px;
    text-align: center;
    border: 1px solid #ccc;
}
.margin-tb{
    margin: 30px 0;
    font-size: 16px;
    font-weight: 600;
}
.textarea{
    height: 250px;
    width: 96%;
    float: right;
    border: 1px solid #ccc;
}
.cread-h2{
    h2{
        margin-left: 44px;
    }
}
.payMoneyDetail-box{
    h1{
        font-size: 18px;
        font-weight: 600;
        margin: 30px 0;
    }
    div{
        height: 36px;
        line-height: 36px;
        span:nth-child(1){
            display: inline-block;
            text-align: right;
            width: 120px;
            font-size: 16px;
            font-weight: 600;
            margin-right: 20px;
        }
        span:nth-child(2){
            font-size: 16px;
        }
    }
}
.editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }