.app-wrapper {
    .sider-menu {
        position: fixed;
        top:64px ;
        left: 0;
        bottom: 0;
        z-index: 1;
        overflow: auto;
        -ms-overflow-style: none; //隐藏滚动条ie10+
        overflow: -moz-scrollbars-none; //Firefox
        background: #2d3a4b;
        .ant-menu-dark,
        .ant-menu-dark .ant-menu-sub {
            background: #2d3a4b;
        }
    }
    //chrome方式隐藏滚动条
    .sider-menu::-webkit-scrollbar {
        width: 0 !important;
    }
    .main-content {
        margin-left: 200px;
    }
    .header {
        position: fixed;
        top: 0;
        left:0;
        right: 0;
        height: 64px;
        z-index: 1;
        background: #2d3a4b;
        padding:0;
        .logo-write{
            width:140px;
            height:32px;
            display:block;
            margin-left: 20px;
        }
        .user-info{
            color:#fff;
            font-size:16px;
            margin-right:30px;
        }
        .user-info  .login-out{
            color:#fff;
        }
    }

    .bread-crumb-content {
        margin: 79px 30px 0;

    }
}

