.search-role-wrapper{
  .menu-operate-purview{
    .ant-form-item{
       margin-bottom:0;
    }
  }
  .tree-node{
    width:500px;
  }
}
.role-add-edit-wrapper .ant-tree li{
   .ant-tree-node-content-wrapper{
    height: 36px;
    line-height: 36px;
  }
  span.ant-tree-checkbox{
    margin: 6px 4px 6px 2px;
  }
 span.ant-tree-switcher,  span.ant-tree-iconEle {
   height: 36px;
   line-height: 36px;
  }
}