.organize-manage-wrapper{
  .tree-graph-wrapper{
    .tree-img{
      width:100%;
      height:80px;
      //overflow:scroll;
    }
    .maintain-finish{
      text-align:right;
      line-height:60px;
      height:60px;
    }
   button{
      margin-right:270px;
    }
    .mask-info{
       width:100px;
       height:80px;
       font-size:16px;
       background:rgba(0, 0, 0, 0.45);

    }
    .mask-info div{
      height:80px;
    }
    .mask-info span{
       height:30px;
       line-height:30px;
       color:#fff!important;
       margin:5px 0;

    }
  }
}
