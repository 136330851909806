.abi-Table{
    h1{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .abi-head{
        margin: 20px 0;
        span{
            font-size: 18px;
            font-weight: 500;
        }
    }
    table{
        width: 100%;
        font-size: 14px;
        tr{
            height: 46px;
            td{
                text-align: center;
            }
            td:nth-child(1){
                width: 10%;
            }
            td:nth-child(2){
                width: 45%;
            }
            td:nth-child(3){
                width: 45%;
            }
        }
    }
}
.ai-box{
    padding:0 20px;
    h1{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 30px;
    }
}
.ah-box{
    padding:20px;
    .ant-table-placeholder{
        z-index: 0;
    }
    h1{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 30px;
    }
}
.ais-info{
    font-size: 16px;
    padding: 20px;
    .ant-col{
        margin:12px 0;
        span:nth-child(1){
            display: inline-block;
            width: 120px;
            text-align: right;
            margin-right: 16px;
        }
    }
}
.ais-box{
    text-align: center;
    button{
        margin-top: 22px;
    }
}
.excel-box{
    margin: 26px 0;
    button{
        margin-right: 20px;
    }
}