.norm-upload-box{
  .upload-text {
    width: 110px;
    font-size: 13px;
    left: 0;
    bottom: -44px;
    line-height: initial;
    height: 38px;
    color: #6B7C93;
  }

  .upload-success-text {
    width: 110px;
    font-size: 13px;
    left: 0;
    bottom: -37px;
    height: 38px;
    line-height: 16px;
    color: #6B7C93;
    text-align: center;
  }

  .avatar-uploader {

    .ant-upload-select-picture-card {
      width: 110px !important;
      height: 110px !important;
    }

    .ant-upload-list-picture-card .ant-upload-list-item {
      width: 110px !important;
      height:110px !important;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
      text-align: center;
      margin-top: 18px !important; //45px;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-progress {
      bottom:  18px!important; //45px;
    }

    .ant-upload-list-item-progress .ant-progress-outer .ant-progress-bg {
      height:  2px !important; // 4px
    }
  }

  .diy-img-preview {
    width:  110px;
    height: 110px;
    z-index: 1;

    > img {
      width:100px;
      height:100px;
      margin-top: 4px;
    }

    .img-preview-bg {
      width: 100px;
      height:100px;
      left: 4px;
      top: 4px;

      .see {
        display: none;
        color: #fff;
        margin: 43px;
      }
      //.see:first-child {
      //  margin-left: 30px;
      //}
    }
  }
  .diy-img-preview:hover .img-preview-bg {
    background: rgba(0, 0, 0, 0.4);
  }

  .diy-img-preview:hover .see {
    display: block;
  }
}
.big-upload-box{
  .upload-text {
    width:320px;
    font-size: 13px;
    left: 0;
    bottom: -44px;
    line-height: initial;
    height: 38px;
    color: #6B7C93;
  }
  .upload-success-text {
    width:320px;
    font-size: 13px;
    left: 0;
    bottom: -37px;
    height: 38px;
    line-height: 16px;
    color: #6B7C93;
    text-align: center;
  }

  .avatar-uploader {
    .ant-upload-select-picture-card {
      width: 320px !important;
      height:454px !important;
    }

    .ant-upload-list-picture-card .ant-upload-list-item {
      width: 320px !important;
      height:454px !important;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
      text-align: center;
      margin-top:150px !important; //45px;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-progress {
      bottom:150px !important; //45px;
    }

    .ant-upload-list-item-progress .ant-progress-outer .ant-progress-bg {
      height: 6px !important; // 4px
    }
    .ant-upload-list-picture-card .ant-upload-list-picture-card-container{
      float:none;
      width: 320px !important;
      height: 454px !important;
    }
  }

  .diy-img-preview {
    width: 320px;
    height:454px;
    z-index: 1;
    > img {
      width:310px;
      height:444px;
      margin-top: 4px;
    }

    .img-preview-bg {
      width:310px;
      height:444px;
      left: 4px;
      top: 4px;
      .see {
        //display: inline-block;
        display: none;
        color: #fff;
        margin: 215px 148px;
      }
      //
      //.see:nth-child(1) {
      //  margin:215px 8px 215px 130px;
      //}
      //.see:nth-child(2) {
      //  margin:215px 130px 215px 8px;
      //}
    }
  }
  .diy-img-preview:hover .img-preview-bg {
    background: rgba(0, 0, 0, 0.4);
  }
  .diy-img-preview:hover .see {
    display: block;
  }

}

