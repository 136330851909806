.paymoney-box{
    padding: 20px;
    h1{
        font-size: 20px;
        font-weight: 600;
        margin: 20px 0;
    }
    div{
        p{
            font-size: 16px;
            span{
                display: inline-block;
                width: 180px;
                font-weight: 500;
                text-align: right;
            }
        }
    }
    .pay-contant{
        border: 1px solid #ccc;
        padding: 30px 0;
    }
    .payMoney-foot{
        margin: 20px 0;
        text-align: center;
        button{
            margin: 0 20px;
        }
    }
}