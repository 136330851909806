@import "../../assets/css/variable";
.login-wrapper{
  background: url("../../assets/img/login.jpg") no-repeat;
  width: 100%;
  height: 100%;
  .login{
    opacity: 0.9;
    position:fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 458px;
    height: 420px;
    margin: auto;
    background:#fff;
    border-radius: $box-radius-size;
    box-shadow: $box-shadow-color;
    > h2 {
      font-size: 22px;
      color: #333;
      font-weight: 600;
      padding: 51px 0 55px;
    }
    .login-form {
      width: 360px;
      margin: auto;
      .ant-form-item {
        margin-bottom: 20px;
      }
      .ant-form-explain{
        position: absolute;
      }
      .ant-input{
        height: 40px;
        line-height: 0 !important;
        margin-bottom: 0px;
        text-indent: 42px;
      }
      .input-user{
        background: url("../../assets/img/icon-user.png") no-repeat;
        background-size: 40px;
      }
      .input-pwd{
        background: url("../../assets/img/icon-pwd.png") no-repeat;
        background-size: 40px;
      }
      .code-login{
        color: #2E83FF;
        font-weight: 400;
      }
      .btn-submit {
        height: 40px;
        font-size: 16px;
      }
      .login-href {
        color: #91A2B0;
      }
      .login-href {
        margin: 20px 0 0 16px;
      }
      .pv-login{
        float: right;
        cursor: pointer;
      }
      .login-foot{
        height: 36px;
        margin: 12px 0;
        line-height: 36px;
        span{
          cursor: pointer;
        }
      }
    }
  }

}
.bg-img{
  width: 100%;
  height: 100%;
  background: url('../../assets/img/login.jpg') no-repeat;
}
.register-box{
    width: 570px;
    margin: 100px auto 0;
    background: white;
    border-radius: 4px;
    padding: 50px 30px 30px 30px;
    .deal-title{
      color: blue;
    }
}
.register-modal-btn{
  width: 200px;
}
.register-title{
  width: 90%;
  margin:0 auto;
  padding: 20px 0;
  border-bottom:1px solid #cccccc;
  .register-h1{
    font-size: 20px;
    color: #f10707;
    margin: 0;
  }
  .register-p{
      width: 100%;
      margin: 0;
  }
}
.box-container div, .box-containers div{
  width: 6rem!important;
  height: 2.3rem!important;
  font-size: 1.6rem!important;
}
.SDP-box{
  width: 570px;
  margin: 20px auto 0;
  background: white;
  border-radius: 4px;
  padding: 50px 30px 30px 30px;
  .start-box{
    position: relative;
    .start-b{
      color: red;
      position: absolute;
      top: 3px;
      left: -80px;
    }
  }
}  

.sms-box{
  width: 632px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 60px;
  .sms-btn{
    width: 100%;
    text-align: center;
    
  }
  .foot-box{
    text-align: right;
    height: 36px;
    line-height: 36px;
    .sms-back{
      display: -webkit-flex;
      display: flex;
      justify-content: space-around;
      background: #cccccc;
      span:nth-child(1){
        float: left;
      }
    }
    span{
      cursor: pointer;
      margin:0 12px;
    }
  }
}