.contractTemplateManage_style {
    .h_text_style {
        margin-bottom: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
    }
    
    .editable-cell {
        position: relative;
    }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
    }

    .editable-row:hover .editable-cell-value-wrap {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 11px;
    }

    .editable-row .ant-form-explain {
        position: absolute;
        font-size: 12px;
        margin-top: -4px;
    }

    .mask-dialog {
        overflow: hidden;
        position: absolute;
        width: 18%;
        height: 40px;
        z-index: 1;
    }
}
