@import "../../assets/css/mixin.scss";
.model-two-wrapper .ant-modal-content{
  .ant-modal-footer div{
    button:first-child{
      float:left;
      margin-left:30px;
      @include btn_no();

    }
    button:last-child{
      float:right;
      margin-right:30px;
      @include btn_opr();
    }
  }
  .ant-modal-footer div::after{
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
  }
}
