
  .figure{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // background-color: #002248;
    // background-color: #1447b5;
    background-image: url(../../assets/img/BgImg7.jpg);
    background-repeat: repeat;
    background-position: -100 0;
    background-size: cover;
    padding: 10px;

    .ant-table-tbody{
      background: transparent;
      animation: scroll 10s linear infinite;
      position: relative;
    }
    @keyframes scroll {
      from { top: 0; }
      to { top: -20 * 30px }
    }
    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body{
      background: transparent;
    }
    .ant-table>.ant-table-content{
      background: transparent;
    }
    .ant-table-tbody > tr > td{
      border-bottom: none;
    }
    .ant-table-thead > tr > th{
      border-bottom: none;
    }
    .flex{
      display: flex;
    }
    .top{
      height: 20%;
      margin-bottom: 10px;
      .wrap{
        position: relative;
        width: 17%;
        height: 100%;
        // border:1px solid black;
        margin-right:10px;
        overflow: hidden;
        margin-bottom: 0px;           
        .inner{
          width: 100%;
          height: 100%;
          // background-color: #12a6ff;
          padding-top: 30px;
          // background: rgba(255,255,255,0.2);
          border: 1px solid #377bc6;
          p{
            // word-break: break-all;
            overflow:hidden; //超出的文本隐藏
            text-overflow:ellipsis; //溢出用省略号显示
            white-space:nowrap; //溢出不换行
          }
        }
      }
    }
    .center{
      height: 50%;
      margin-bottom: 10px;
    
    }
    .bottom{
      height: 27%;
      .BotL{
        width: 60%;
        height: 100%;
        // margin-right: 10px;
        // background: rgba(255,255,255,0.2);
        border: 1px solid #377bc6;
        margin-right: 10px;
        overflow: hidden;
        position:relative;
      }
      .BotR{
        width: 40%;
        height: 100%;
        // background: rgba(255,255,255,0.2);
        border: 1px solid #377bc6;
        overflow: hidden;
        position:relative;
      }
      // 滚动条的组成：
// ::-webkit-scrollbar 滚动条整体部分
// ::-webkit-scrollbar-thumb 滚动条里面的小方块，能上下左右移动（取决于是垂直滚动条还是水平滚动条）
// ::-webkit-scrollbar-track 滚动条的轨道（里面装有thumb）
// ::-webkit-scrollbar-button 滚动条轨道两端的按钮，允许通过点击微调小方块的位置
// ::-webkit-scrollbar-track-piece 内层轨道，滚动条中间部分（除去）
// ::-webkit-scrollbar-corner 边角，及两个滚动条的交汇处
// ::-webkit-resizer 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
.ant-table-body{
  &::-webkit-scrollbar {
    width: 3px;
    height: 2px;
    // background-color:  #002248 !important;
    // background-color:  #0d045d !important;
    background-color:#333f4d;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #8dd7fc;
  }
  &::-webkit-scrollbar-track {
    width: 3px;
    -webkit-box-shadow: 0;
    border-radius: 0;
    // background: #1447b5;
    // background: #0d045d;
    background-color:#333f4d;
  }

 
}
// .ant-breadcrumb {
// &> span {
// &:last-child {
// color:#999;
// font-weight: normal;
// }
// }
// }
// .ant-breadcrumb-link {
// .anticon + span {
// margin-left:4px;
// }
// }


//表头
.ant-table-thead > tr > th {
  // background-color: #002248 !important;
  // background-color: #1447b5 !important;
  // background-color:transparent;
    background: transparent;
    height: 65px;
  .ant-table-column-title{
    // color: #8dd7fc !important;
    color:#fff !important;
  }

 
}
//表头滚动轨道
.ant-table-scroll{
  .ant-table-hide-scrollbar{
    overflow: hidden !important;
    // background-color:  #002248 !important;
    // background-color:  #1447b5 !important;
    background-color:transparent;
  }
}

//暂无数据表格显示
.ant-table-placeholder{
  background-color:transparent;
  border-top: none;
  border-bottom: none;
  // border-top:none;
  // padding-top: 0px;
}

.ant-empty-normal{
  color: #fff;
}

}

.moneyColor{
  color:#fff;
}
.bgc{
  background-color:transparent;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #667ea0;
}
.bgc2{
  background-color:red;
}
.rgb{
   background: rgba(255,255,255,0.1);
}
.border{
  border-left: 1px solid #84c3f7;
  border-bottom: 1px solid #84c3f7;
}
.echarts{
  height: 100%;
  position: relative;
  border: 1px solid #377bc6;

}
.back{
  position: absolute;
  left:25px;
  top: 25px;
  // color: rgb(179, 239, 255);
  color:#fff;
  font-size: 16px;
  cursor: pointer;
  z-index: 100
}
.fontSize32px{
  width: 100%;
  font-size: 32px;
  margin-bottom: 0px;
  word-break:break-all;
  text-align: center;
  // color:rgb(179, 239, 255)
  color:#fff
}
.fontSize22px{
  width: 100%;
  font-size: 22px;
  text-align: center;
  // color:rgb(179, 239, 255);
  color:#fff
}
.box{
  width: 15px;
  height: 15px;
  background: transparent;
  position: absolute;
}
.box1{
  left: 0;
  top: 0;
  border: 1px solid transparent; 
  border-top: 2px solid rgb(57, 136, 240);
  border-left: 2px solid rgb(57, 136, 240);
}
.box2{
  right: 0;
  top: 0;
  border: 1px solid transparent; 
  border-top: 2px solid rgb(57, 136, 240);
  border-right: 2px solid rgb(57, 136, 240);
}
.box3{
  left: 0;
  bottom: 0;
  border: 1px solid transparent; 
  border-bottom: 2px solid rgb(57, 136, 240);
  border-left: 2px solid rgb(57, 136, 240);
}
.box4{
  right: 0;
  bottom: 0;
  border: 1px solid transparent; 
  border-bottom: 2px solid rgb(57, 136, 240);
  border-right: 2px solid rgb(57, 136, 240);
}
.boxHeader{
  width: 100%;
  height: 20%;
  display: flex;
  .left{
    width: 40%;
    height: 100%;
    // background-color: red;
    border-top: 1px solid #377bc6;
    border-left: 1px solid #377bc6;
    position: relative;
  }
  .center{
    width: 10%;
    height: 100%;
    // background-color: transparent;
    // margin-left:50px;
    // box-sizing:border-box;
    // border:1px solid #ddd;
    // background:linear-gradient(14deg,transparent 47.5%, #84c3f7 49.5%, #84c3f7 52.5%, transparent 50.5%)
    // background:linear-gradient(20.5deg,transparent, transparent 47%,#84c3f7, transparent 53%, transparent 100%)
    // border: 1px solid #333;
    background: linear-gradient(
       to top right,
       rgba(0, 0, 0, 0.1) 0%,
       rgba(0, 0, 0, 0.1) calc(50% - 1px),
       #377bc6 50%,
       rgba(0, 0, 0, 0.1) calc(50% + 1px),
       rgba(0, 0, 0, 0.1) 100%
    ), 
  //  linear-gradient(
  //      to bottom right,
  //      rgba(0, 0, 0, 0.1) 0%,
  //      rgba(0, 0, 0, 0.1) calc(50% - 1px),
  //      #84c3f7 50%,
  //      rgba(0, 0, 0, 0.1) calc(50% + 1px),
  //      rgba(0, 0, 0, 0.1) 100%
  //   );

  }
  
    
  .right{
    width: 50%;
    height: 100%;
    // background-color: green;
    border-bottom: 1px solid #377bc6;
   
  }
}
.boxBody{
  height: 80%;
  border-right: 1px solid #377bc6;
  border-left: 1px solid #377bc6;
  border-bottom: 1px solid #377bc6;
  position: relative;
  // animation: move2 1s infinite linear;
  // background: linear-gradient(0, #84c3f7 2px, red 2px) no-repeat, 
  //               linear-gradient(-90deg, yellow 2px, blue 2px) no-repeat, 
  //               linear-gradient(-180deg, #108b96 2px, #108b96 2px) no-repeat, 
  //               linear-gradient(-270deg, #108b96 2px, #108b96 2px) no-repeat;
  //   background-size: 100% 2px, 2px 100%, 100% 2px, 2px 100%;
  //   background-position: left top, right top, right bottom, left bottom;
}

// }
.monthTitle{
  text-align:left;
  font-size:20px;
  color: #fff;
  padding-left: 20px;
  box-sizing: border-box;
}
.province{
  padding-top: 20px;
  padding-left: 20px;
  box-sizing: border-box;
}
.rowStyle{
  background-color: #9e3;
}


$cellHeight: 30px;
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrapTable{
  width: 100%;
}
.header {
  width: 100%;
  height: 40px;
  display: flex;
}
.bodyWrap {
  width: 100%;
  height: 162px;
  overflow: hidden;
  position: relative;
  // padding-bottom: 10px;
  li {
    display: flex;
    height: $cellHeight;
    color: #fff;
    span{
      display: block;
      // flex: 1;
    }
  }
}
.cell {
  // flex: 1;
  height: $cellHeight;
  line-height: $cellHeight;
  // border: 1px solid #e2e2e2;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
}
.cellFm-cfbicipbankLevelCdDesc,.cellFm-amount,.cellFm-rate{
  width: 20%;
}
.cellFm-date{
  width: 40%;
}
.time{
  width: 15%;
}
.person{
  width: 36%;
  overflow: hidden;
}
.other{
  width: 5%;
  overflow: hidden;
}
.discount{
  width: 9%;
}
.faceValue{
  width: 9%;
}
.channelTypeName{
  width: 6%;
}
#list,#listFm{
  width:100%;
  // animation: scroll 10s linear infinite;
  position: absolute;
  left: 0;
  top: 0;

}

// @keyframes scroll {
//   from { top: 0; }
//   to { top: -162px }
// }













  }

 

  



  
 