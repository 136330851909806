.notfond-box{
    text-align: center;
    margin: 100px 0;
    img{
        margin: 28px 0;
    }
    .notfond-sorry{
        margin: 28px 0;
    }
    .notfond-box-bold{
        span{
            font-weight: 600;
            font-size: 30px;
            color: #1890ff;
        }
    }
}