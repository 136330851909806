.BorrowerCredit_style {
    .addComp_style {
        .ant-form-item {
            margin: 12px
        }
    }
    .h_text_style {
        margin-bottom: 20px;
        color: rgba(0,0,0,.85);
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
    }
    // .enble_form_style .ant-form-explain {
    //     position: absolute;
    //     // font-size: 12px;
    //     margin-top: -4px;
    // }
}