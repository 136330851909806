@import "../../assets/css/variable";

.business-role-select-wrapper {
  background: url("../../assets/img/login.jpg") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  min-height: 100%;
  padding: 30px;

  .business-role-select-content {
    opacity: 0.9;
    width: 1000px;
    min-height: 500px;
    margin: 20px auto;
    background: #fff;
    border-radius: $box-radius-size;
    box-shadow: $box-shadow-color;
    padding: 30px;

    h5 {
      font-size: 22px;
      margin-bottom: 0;
    }

    .second-title {
      font-size: 16px;
      margin-bottom: 50px;
    }

    .select-option {
      width: 300px;
      margin-left: 10px;
    }

    .checkout-company {
      margin-bottom: 20px;
    }

    .gutter-row {
      margin-bottom: 20px;
    }

    .account-box {
      background: #d4d7d9;
      padding: 20px;

      h5 {
        margin-bottom: 0;
      }

      ul {
        border: 1px dashed #E8F0F5;
        margin-bottom: 0;
        height: 200px;
        overflow-y: auto;
      }

      li {
        font-size: 13px;
        height: 35px;
        line-height: 35px;
        text-indent: 15px;

        span {
          color: #333;
          padding-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-indent: 0;
          display: inline-block;
          white-space: nowrap;
        }
      }
    }

    .account-status {
      color: #E49412;
      font-size: 13px;
      padding-left: 32px;
    }

    .check-btn-wapper {
      margin-top: 20px;
    }

    .check-btn {
      width: 100px;
    }
  }
}

