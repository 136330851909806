@import "../../assets/css/mixin";
.fontSize16px{
  font-size: 16px;
}
.fontSize20px{
  font-size: 20px;
}
.fontSize24px{
     font-size: 24px;
}
.fontSize40px{
     font-size: 40px;
}
.companyName-box{
  font-size: 24px;
  color:orange;
}
.bc-title{
  font-size: 20px;
  margin: 38px 0;
}
.status-title{
  float: left;
  margin-right: 28px;
  line-height: 32px;
}
.status-box{
  float: left;
  font-size: 16px;
  span{
    font-weight: 600;
  }
  button{
    margin-left: 20px;
  }
}
.es-title{
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
}
.opened-box{
  border: 1px solid #ccc;
  padding: 36px 16px;
  .content{
    ul{
      width: 100%;
      display: flex;
      li{
        text-align: center;
        width: 200px;
      }
    }
  }
}
.detail-span{
  color: blue;
  font-size: 16px;
  margin-left: 20px;
  cursor: pointer;
}
.es-refuse{
  font-size: 18px;
  font-weight: 600;
  margin:40px 0;
}
.content{
  display: flex;
  justify-content:space-around;
  align-items: center;

}
.content-flex{
   width: 80%;
   border: 1px solid black;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px 0;
   overflow: hidden;
   li{
     overflow: hidden;
     text-overflow: ellipsis;
     flex: 1;
     text-align: center;
   }
}
.marginBottom-flex{
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.notOpen{
  overflow: hidden;
}
.content{
  display: flex;
  justify-content:space-around;
  align-items: center;
  margin-top: 50px;
  .content-flex{
    display: flex;
  justify-content: center;
  align-items: center;
  ul{
    flex: 1;
    li{
      font-size: 20px;
    }
  }
  }
}
.fontSize30px{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-size: 28px !important;
  padding: 0 10px;
  cursor:pointer;
}