.menu-table{
  .ant-table-tbody{
    .ant-table-row-level-0 .menuName {
       text-align:left;

    }
    .ant-table-row-level-1 .menuName{
       text-align:right;
    }
  }
  .menuName{
     width:200px;
     padding:16px;
  }
}